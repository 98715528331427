import React from "react";
import Footer from "src/components/Footer_pt";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/pt_PT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/pt/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/pt/nossos-produtos/" target="_self">
                      Produtos
                    </a>
                    <ul>
                      <li>
                        <a href="/pt/nossos-produtos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/pt/nossos-produtos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/pt/nossos-produtos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">
                          Todos os Produtos
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/a_nossa_historia/" target="_self">
                      A Nossa História
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/pt/sobre_o_sol/" target="_self">
                      Aprenda Sobre o Sol
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/pt/Por-Onde-Andaste/" target="_self">
                      Por Onde Andaste 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      PAÍSES
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/tan_protect/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2020-tan-protect.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2020-tan-protect-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Melhora o bronzeado natural</h3>
                      <p>
                        Seja nos fins de semana em que foge da cidade para
                        apanhar um pouco de sol ou planeia ir à praia para umas
                        longas e relaxantes férias, os nossos Óleos, Loções e
                        Sprays PIZ BUIN TAN &amp; PROTECT<sup>®</sup> vão
                        ajudá-lo a tirar o maior partido do seu tempo ao sol.
                        PIZ BUIN TAN &amp; PROTECT<sup>®</sup> combina a
                        proteção eficaz contra os raios UVA/UVB com o Illumitone
                        <sup>™</sup>. Ao aumentar a produção do pigmento
                        bronzeador natural da pele até 70%*, está comprovado que
                        o Illumitone<sup>™</sup> acelera a velocidade do
                        processo natural do bronzeado ** para obter um bronzeado
                        natural mais rápido*** e deslumbrante.
                      </p>
                      <p className="footNote">
                        *Sprays e Loções, testados in vitro, ** teste in vitro,
                        *** Contém uma tecnologia que acelera o bronzeado
                        natural
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="-leo-spray-acelerador-de-bronzeado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>ÓLEO SPRAY ACELERADOR DE BRONZEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          O nosso óleo spray acelerador de bronzeado ajuda-o a
                          obter rapidamente um bronzeado seguro e deslumbrante –
                          permitindo-o aproveitar cada momento que passar ao sol
                          este verão.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Os óleos bronzeadores tradicionais podem fazer com
                            que o seu bronzeado pareça fantástico mas tendem a
                            oferecer pouca ou nenhuma proteção solar, enquanto
                            que os cremes protetores solares tradicionais, com
                            fatores de proteção solar elevados, podem significar
                            ter de esperar muito tempo para obter a cor do verão
                            que tanto deseja. Como é que consegue obter um
                            bronzeado deslumbrante e natural mais rápido sem ter
                            de comprometer a proteção da sua pele?
                          </p>
                          <p>
                            O PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Óleo Spray
                            Acelerador de Bronzeado é tudo o que precisa. O
                            nosso óleo spray de rápida absorção, não gorduroso e
                            de fácil aplicação, combina a proteção eficaz contra
                            os raios UVA/UVB com o Melitane<sup>™</sup>, um
                            ingrediente inovador que acelera o processo
                            bronzeador natural da pele*. PIZ BUIN TAN &amp;
                            PROTECT<sup>®</sup> Óleo Spray Acelerador de
                            Bronzeado. Para obter um bronzeado deslumbrante mais
                            rápido... com segurança!
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Óleo Spray
                            Acelerador de Bronzeado é resistente à transpiração
                            e à água.
                          </p>
                          <p>
                            O PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Óleo Spray
                            Acelerador de Bronzeado é aprovado por consumidores.{" "}
                          </p>
                          <p>
                            Num estudo perguntámos a consumidores a sua opinião
                            sobre o nosso óleo spray acelerador de bronzeado.
                            Após 4 semana de teste...
                          </p>
                          <p>
                            <strong className="orange">80%**</strong> confirmam:
                          </p>
                          <ul>
                            <li>Bronzeado natural acelerado</li>
                          </ul>
                          <p>
                            <strong className="orange">84%***</strong>{" "}
                            confirmam:
                          </p>
                          <ul>
                            <li>Brilho deslumbrante</li>
                          </ul>
                          <p>
                            <strong className="orange">97%**</strong> confirmam:
                          </p>
                          <ul>
                            <li>Proteção solar eficaz</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          diminui significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol. Não
                          aplique o spray diretamente no rosto. Para uso no
                          rosto coloque nas mãos e depois aplique, evitando o
                          contacto com os olhos.
                        </p>
                        <p className="footNote">*Teste in vitro</p>
                        <p className="footNote">
                          **Resultados obtidos de um estudo de opinião, sobre o
                          óleo spray com FPS 30. 89 indivíduos, durante 4
                          semanas com, pelo menos, uma aplicação diária.
                        </p>
                        <p className="footNote">
                          ***Resultados obtidos de um estudo de opinião, sobre o
                          óleo spray com FPS 30. 40 indivíduos, que usaram o
                          óleo spray enquanto apanhavam sol, durante 4 semanas
                          com, pelo menos, uma aplicação diária.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    A Vitamina E é um poderoso antioxidante
                                    natural que ajuda a proteger dos radicais
                                    livres que danificam as células da pele* e
                                    podem causar o envelhecimento prematuro da
                                    mesma.
                                  </p>
                                  <p className="footNote">*Testado in vitro</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está comprovado que o Illumitone™ acelera o
                                    processo natural do bronzeado. Aumenta a
                                    produção do pigmento natural do corpo
                                    (melanina) responsável pelo bronzeado em até
                                    70%*. Não mancha roupa, fatos de banho ou
                                    toalhas.
                                  </p>
                                  <p className="footNote">
                                    * Sprays e Loções, teste in vitro
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="lo-o-solar-intensificadora-do-bronzeado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/eff51ba86541ed918048fedf6e5e85ee_f28.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOÇÃO SOLAR INTENSIFICADORA DO BRONZEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          A fórmula não gordurosa e não pegajosa do PIZ BUIN TAN
                          &amp; PROTECT<sup>®</sup> Loção Solar Intensificadora
                          do Bronzeado, hidrata intensamente a pele, o que ajuda
                          a prevenir a descamação e contribui para um bronzeado
                          mais duradouro.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Loção Solar
                            Intensificadora do Bronzeado é resistente à
                            transpiração e à água.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar em doses generosas e uniformemente antes da
                          exposição ao sol. A aplicação de uma dose reduzida
                          reduz significativamente o nível de proteção.
                          Reaplique frequentemente, especialmente depois de
                          transpirar, nadar ou secar-se com uma toalha. Evite a
                          exposição solar ao meio-dia e ao longo de muitas
                          horas, mesmo quando usa proteção solar. Manter bebés e
                          crianças afastados da exposição direta ao sol.
                        </p>
                        <h2>Disponível nos formatos</h2>
                        <p>
                          <em />
                          150 ml
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    A Vitamina E é um poderoso antioxidante
                                    natural que ajuda a proteger dos radicais
                                    livres que danificam as células da pele* e
                                    podem causar o envelhecimento prematuro da
                                    mesma.
                                  </p>
                                  <p className="footNote">*Testado in vitro</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está comprovado que o Illumitone™ acelera o
                                    processo natural do bronzeado. Aumenta a
                                    produção do pigmento natural do corpo
                                    (melanina) responsável pelo bronzeado em até
                                    70%*. Não mancha roupa, fatos de banho ou
                                    toalhas.
                                  </p>
                                  <p className="footNote">
                                    * Sprays e Loções, teste in vitro
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="spray-solar-intensificador-do-bronzeado" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLAR INTENSIFICADOR DO BRONZEADO</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFÍCIOS</h4>
                        <p>
                          O nosso Spray Solar Intensificador do Bronzeado
                          combina uma proteção. UVA/UVB eficaz com Illumitone
                          <sup>™</sup> que acelera o bronzeado natural*
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Proteção instantânea. Absorção imediata. Resistente
                            à água.
                          </p>
                          <p>Nota: Não contém autobronzeador.</p>
                          <p className="ccm-note footNote">*Testes ex vivo</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Saiba Mais
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>DISPONÍVEL NOS FATORES DE PROTEÇÃO SOLAR</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>RECOMENDAÇÃO DE USO</h4>
                        <p>
                          Aplicar generosamente e reaplicar com frequência. Uma
                          quantidade insuficiente reduz o nível de proteção.{" "}
                          <br />
                          Evitar o sol ao meio-dia e exposições prolongadas.
                          Manter bebés e crianças afastados da exposição direta
                          ao sol. <br />
                          Para uso facial, pulverizar nas mãos e aplicar. Usar
                          com precaução já que o solo pode tornar-se
                          escorregadio durante o uso.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTES E TECNOLOGIAS{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                SISTEMA DE FILTROS SOLARES AVANÇADOS UVA/UVB
                                <div className="textBlock bottom">
                                  <p>
                                    Como especialistas em proteção solar,
                                    utilizamos tecnologias inovadoras para nos
                                    certificarmos que os nossos clientes atingem
                                    um equilíbrio ótimo de cor e proteção sempre
                                    que desfrutam do sol.
                                  </p>
                                  <p>
                                    Cada sistema de filtros solares avançados
                                    UVA/UVB desenvolvido por PIZ BUIN
                                    <sup>®</sup> é uma poderosa tecnologia de
                                    proteção solar. Oferecem fotoestabilidade
                                    eficaz e um amplo espetro de proteção UVA e
                                    UVB, colocando os protetores solares PIZ
                                    BUIN<sup>®</sup> em conformidade com as mais
                                    recentes normas Europeias em produtos
                                    solares*.
                                  </p>
                                  <p>
                                    Todos os sistemas de filtros solares
                                    avançados UVA/UVB ajudam a proteger das
                                    radiações UVA e UVB.
                                  </p>
                                  <p>
                                    Raios UVB - prejudicam a superfície da pele
                                    e são a principal causa de queimaduras
                                    solares.
                                  </p>
                                  <p>
                                    Raios UVA - penetram mais profundamente e
                                    danificam o colagénio e a elastina, o que
                                    pode resultar em envelhecimento prematuro da
                                    pele e cancro.
                                  </p>
                                  <p>
                                    O sistema de filtros solares avançados
                                    UVA/UVB contém filtros UVA e UVB que ajudam
                                    a proteger do sol e dos danos a longo prazo
                                    na pele enquanto protegem dos efeitos mais
                                    graves da radiação UVA. Estes filtros cobrem
                                    a maior parte do espetro de radiação
                                    UVA/UVB, oferecendo uma proteção mais ampla
                                    à sua pele.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *Recomendação da Comissão Europeia
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMINA E
                                <div className="textBlock bottom">
                                  <p>
                                    A Vitamina E é um poderoso antioxidante
                                    natural que ajuda a proteger dos radicais
                                    livres que danificam as células da pele* e
                                    podem causar o envelhecimento prematuro da
                                    mesma.
                                  </p>
                                  <p className="footNote">*Testado in vitro</p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    Está comprovado que o Illumitone™ acelera o
                                    processo natural do bronzeado. Aumenta a
                                    produção do pigmento natural do corpo
                                    (melanina) responsável pelo bronzeado em até
                                    70%*. Não mancha roupa, fatos de banho ou
                                    toalhas.
                                  </p>
                                  <p className="footNote">
                                    * Sprays e Loções, teste in vitro
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/pt/nossos-produtos/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/pt/nossos-produtos/active-protect/"
                      target="_self"
                    >
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/pt/nossos-produtos/tan_protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/pt/nossos-produtos/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/pt/nossos-produtos/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/pt/nossos-produtos/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a
                      href="/pt/nossos-produtos/todos_os_Produtos/"
                      target="_self"
                    >
                      Todos os Produtos
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinPortugal"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://www.instagram.com/pizbuinportugal/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UCuRXMca5zj_v97YlGJip63A"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Saiba Mais');\n} else {\nbox.slideDown();\n$(this).text('Fechar');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
